import React, { useContext, useState } from 'react'
import SbEditable from 'storyblok-react'
import Layout from '../layout'
import { ThemeProvider, makeStyles } from '@material-ui/styles'
import renderBloks from '@renderBloks'
import renderBlok from '@renderBlok'
import { Box, CssBaseline } from '@material-ui/core'
import ACC_THEME from '../../themes'
import {
  H5,
  H3,
  H4,
  MarkdownHandler,
  Img,
  VideoScreenshotsPreview,
  PageContainer,
  ContentContainer,
} from '@system'
import PageContext from '@context'
import get from 'lodash/get'
import PageValidationForm from '@helpers/page-validation-form'
import getFormattedDateTime from '@helpers/get-formatted-date-time'
import font from '@helpers/font'
import { imgMaxWidths } from '@variables/global-variables'
import * as dayjs from 'dayjs'
import GoogleStructuredData from './google-structured-data'

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

const { makeResponsiveFontSizes } = font

const useStyles = makeStyles({
  headerImage: {
    width: '100%',
    height: '66vh',
    maxHeight: '600px',
    objectPosition: 'top',
    '& img': {
      objectFit: 'cover',
    },
  },
  body: {
    marginTop: (props) => (props.isHeaderImage > 0 ? '-280px' : '0px'),
    paddingTop: (props) => (props.isHeaderImage > 0 ? '140px' : '100px'),
    position: 'relative',
    [ACC_THEME.breakpoints.down('sm')]: {
      paddingTop: '90px !important',
    },
  },
  innerContainer: {
    paddingTop: '60px',
    paddingBottom: '60px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    backgroundColor: ACC_THEME.palette.common.white,
    position: 'relative',
    margin: '-80px auto auto auto',
  },
  contentColumn: {
    width: '65%',
    [ACC_THEME.breakpoints.between('sm', 'md')]: {
      width: '60%',
    },
    [ACC_THEME.breakpoints.down('sm')]: {
      width: '100%',
      paddingBottom: '40px',
    },
  },
  logo: {
    marginBottom: '20px',
    '& img': {
      width: '160px',
      objectFit: 'contain',
    },
  },
  title: ({ title }) => ({
    color: ACC_THEME.palette.secondary.main,
    lineHeight: '47px',
    maxWidth: '784px',
    display: 'flex',
    ...makeResponsiveFontSizes(
      // proportional font size reduction for title longer than 35 characters
      title ? (title.length <= 35 ? 1 : 35 / title.length) * 64 : 64
    ),
  }),
  formattedDate: {
    fontSize: '25px',
    color: ACC_THEME.palette.secondary.main,
    marginTop: '20px',
    marginBottom: '25px',
  },
  markdownText: {
    color: ACC_THEME.palette.text.tertiary,
  },
  screenshotsContainer: {
    marginTop: '40px',
  },
  contactColumn: {
    backgroundColor: ACC_THEME.palette.background.slite,
    width: '30%',
    alignSelf: 'flex-start',
    padding: '22px',
    [ACC_THEME.breakpoints.between('sm', 'md')]: {
      width: '36%',
    },
    [ACC_THEME.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0 auto',
      padding: '40px 5%',
      order: 3,
    },
  },
  offsiteRegistration: {
    padding: '15px 0px',
  },
})

const EventDetail = (props) => {
  const {
    metaData,
    image,
    logo,
    title,
    description,
    bodyText,
    endDate,
    startDate,
    photos,
    isVirtualEvent,
    body,
    passwords,
    passwordProtect,
    offsiteRegistration,
  } = props.blok

  const isHeaderImage = image && image.length
  const classes = useStyles({ ...props.blok, isHeaderImage: isHeaderImage })
  const pageContext = useContext(PageContext)

  const virtualEvent = ' - Virtual Event'
  const variant = 'events'
  const headerImage = get(image, '[0].image')

  const [isPasswordProtected, setIsPasswordProtected] =
    useState(passwordProtect)

  const getEventsDateAndTime = (startDate, endDate) => {
    const yearOfStartDate = getFormattedDateTime(startDate, 'YYYY')
    const yearOfEndDate = getFormattedDateTime(endDate, 'YYYY')
    const monthOfStartDate = getFormattedDateTime(startDate, 'MMMM')
    const monthOfEndDate = getFormattedDateTime(endDate, 'MMMM')

    if (dayjs(startDate).isSame(dayjs(endDate))) {
      return `${getFormattedDateTime(startDate, 'MMMM DD')}, ${yearOfStartDate}`
    } else if (yearOfStartDate === yearOfEndDate) {
      return monthOfStartDate === monthOfEndDate
        ? `${getFormattedDateTime(
            startDate,
            'MMMM DD'
          )} - ${getFormattedDateTime(endDate, 'DD')}, ${getFormattedDateTime(
            startDate,
            'YYYY'
          )}`
        : `${getFormattedDateTime(
            startDate,
            'MMM DD'
          )} - ${getFormattedDateTime(endDate, 'MMM DD')}, ${yearOfStartDate}`
    } else {
      return `${getFormattedDateTime(
        startDate,
        'MMM DD'
      )} - ${getFormattedDateTime(endDate, 'MMM DD')}`
    }
  }

  const screenshots =
    photos &&
    photos.map((photoGallery) => {
      return {
        url: photoGallery.image,
        url_preview: photoGallery.image,
      }
    })

  const eventData = {
    publishedAt: pageContext.first_published_at,
    ...props.blok,
  }

  return (
    <Layout metaData={metaData}>
      <ThemeProvider theme={ACC_THEME}>
        <CssBaseline />
        <GoogleStructuredData type="Event" data={eventData} />
        {isPasswordProtected && passwords.length > 0 ? (
          <PageValidationForm
            passwords={passwords}
            updateIsPasswordProtected={() => setIsPasswordProtected(false)}
          />
        ) : (
          <SbEditable content={props.blok}>
            <Box>
              {headerImage && (
                <Img
                  className={classes.headerImage}
                  src={headerImage}
                  maxWidth={imgMaxWidths.headerImage}
                />
              )}
              <Box className={classes.body}>
                <PageContainer>
                  <ContentContainer className={classes.innerContainer}>
                    <Box className={classes.contentColumn}>
                      {logo && (
                        <Box className={classes.logo}>
                          <img src={logo.filename} alt={logo.name} />
                        </Box>
                      )}
                      {title && (
                        <>
                          <H3 component="h1" className={classes.title}>
                            {title}
                          </H3>
                          <H4 className={classes.formattedDate}>
                            {getEventsDateAndTime(startDate, endDate)}
                            {!!isVirtualEvent && virtualEvent}
                          </H4>
                        </>
                      )}
                      {description && (
                        <H5 className={classes.markdownText}>
                          <MarkdownHandler>{description}</MarkdownHandler>
                        </H5>
                      )}
                      {photos && (
                        <Box className={classes.screenshotsContainer}>
                          <VideoScreenshotsPreview screenshots={screenshots} />
                        </Box>
                      )}
                      {bodyText && (
                        <H5 className={classes.markdownText}>
                          <MarkdownHandler>{bodyText}</MarkdownHandler>
                        </H5>
                      )}
                    </Box>
                    <Box className={classes.contactColumn}>
                      {offsiteRegistration && offsiteRegistration.length > 0 ? (
                        <Box className={classes.offsiteRegistration}>
                          {renderBloks(offsiteRegistration)}
                        </Box>
                      ) : (
                        pageContext.eventsForm &&
                        renderBlok(pageContext.eventsForm, { variant })
                      )}
                    </Box>
                  </ContentContainer>
                </PageContainer>
                {body && <Box>{renderBloks(body)}</Box>}
              </Box>
            </Box>
          </SbEditable>
        )}
      </ThemeProvider>
    </Layout>
  )
}

export default EventDetail
